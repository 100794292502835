import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import fullScreen from 'vue-fullscreen';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './utils';
import guard from './utils/guard';
import './registerServiceWorker';
import i18n from './i18n';
import './tailwind.css';
import './main.css';
import './mapBox.css';
import '../node_modules/mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './firebase/index.js';

Vue.use(utils);
Vue.use(guard);
Vue.use(VueTheMask);
Vue.use(fullScreen);

Vue.config.productionTip = false;

// Add directive
Vue.directive('click-outside', {
    bind: (el, binding, vnode) => {
        el.clickOutsideEvent = (event) => {
            if (
                !(el === event.target || el.contains(event.target)) &&
                event.target.className !== 'my-item'
            ) {
                vnode.context[binding.expression](event);
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: (el) => {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
