<template>
    <div>
        <div
            v-if="user"
            :class="
                classNames(
                    'rounded-4xl cursor-pointer bg-white shadow-bold w-[42px] h-[42px]',
                    'flex items-center justify-center z-10',
                    'absolute top-3 right-3 shadow-lg hover:opacity-90 hover:transition-all',
                )
            "
            @click="onOpenDialogProfile">
            <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                <span class="font-medium text-gray-600 select-none">{{ $utils.getLastName(user.name) }}</span>
            </div>
        </div>
        <div
            v-else
            :class="
                classNames(
                    'rounded-4xl cursor-pointer bg-white shadow-bold w-[42px] h-[42px]',
                    'flex items-center justify-center z-10',
                    'absolute top-3 right-3 shadow-lg hover:opacity-90 hover:transition-all',
                )
            "
            @click="onOpenDialogUser">
            <span class="text-[25px] mdi mdi-account-outline text-primary"></span>
        </div>
        <t-dialog v-if="diUser" width="xs:w-[90%] sm:w-[30%]" height="xs:h-[90%] sm:h-[90%]" @eClose="diUser = false">
            <template>
                <ResendOTP
                    v-show="type === 'resend'"
                    @eChangeType="(e) => (type = e)"
                    @eClose="diUser = false" />
                <Login v-show="type === 'login'" @eChangeType="(e) => (type = e)" @eClose="diUser = false" />
                <register
                    v-show="type === 'register'"
                    @eChangeType="(e) => (type = e)"
                    @eClose="diUser = false" />
            </template>
        </t-dialog>

        <div v-if="diProfile">
            <profile-user @eClose="diProfile = false" />
        </div>
    </div>
</template>

<script>
import mixinPrettyClassname from '@/components/app/mixins/mixinPrettyClassname';
import TDialog from '@/components/tailwind/TDialog.vue';
import ProfileUser from '@/components/app/dialog/ProfileUser.vue';
import { mapGetters } from 'vuex';

// View
const Login = () => import('@/views/Login');
const Register = () => import('@/views/Register');
const ResendOTP = () => import('@/views/ResendOTP');

export default {
    components: { TDialog, Login, Register, ProfileUser, ResendOTP },
    name: 'AppUser',
    mixins: [mixinPrettyClassname],
    data() {
        return {
            diProfile: false,
            diUser: false,
            type: 'login',
        };
    },
    computed: {
        ...mapGetters(['getTempUser']),

        user() {
            return this.getTempUser;
        },
    },
    methods: {
        onOpenDialogUser() {
            this.diUser = true;
        },
        onOpenDialogProfile() {
            this.diProfile = !this.diProfile;
        },
    },
};
</script>

<style></style>
