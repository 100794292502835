import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyDIjOHoBbArAfW6xxFWCkd8pODNgKSdKi4',
    authDomain: 'phoneverify-31c89.firebaseapp.com',
    projectId: 'phoneverify-31c89',
    storageBucket: 'phoneverify-31c89.appspot.com',
    messagingSenderId: '504092798508',
    appId: '1:504092798508:web:3392f1fb6906dd196b6b89',
    measurementId: 'G-0GFMWKFJVV',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export { firebase };
