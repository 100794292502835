export default {
    getTempUser(state) {
        return state.tempUser;
    },
    getTempMapUser(state) {
        return state.tempMapUser;
    },
    getToken(state) {
        return state.cartoToken;
    },

    getDialogPopup(state) {
        return state.dialogPopup;
    },
};
