<template>
    <div
        class="bg-white p-3 border rounded-xl top-[60px] right-3"
        style="width: 270px; transform-origin: center top; z-index: 2013; position: fixed"
        x-placement="bottom-end">
        <!---->
        <div>
            <div class="flex justify-between">
                <div class="flex">
                    <div
                        class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-200 rounded-full">
                        <span class="font-medium">{{ $utils.getLastName(this.$guard.user.name) }}</span>
                    </div>
                    <div class="w-4"></div>

                    <div>
                        <p class="normal-font !text-[15px] !font-600 mb-1">{{ user.name }}</p>
                        <p class="normal-font !font-500 crop">{{ user.phone }}</p>
                    </div>
                </div>
                <span @click="$emit('eClose')" class="text-[30px] mdi mdi-close text-primary cursor-pointer"></span>
            </div>
            <div class="h-2"></div>
            <div class="bg-white rounded-normal px-4 pt-2 pb-0">
                <p class="normal-font !font-600 !text-[15px]">0 lượt tra cứu</p>
            </div>
            <div data-v-6a2f8c42="">
                <div data-v-6a2f8c42="" class="bg-white rounded-normal mt-0">
                    <a
                        @click="logout"
                        class="h-[54px] cursor-pointer w-full no-underline flex items-center px-4 btn-menu-feature transition"
                        ><span data-v-6a2f8c42="" class="normal-font !font-500 !text-[15px]">Đăng xuất</span></a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
import apiService from '@/apiService';
export default {
    name: 'ProfileUser',
    data() {
        return {
            form: {
                phone: null,
                name: null,
            },
            diProfile: true,
        };
    },
    computed: {
        user() {
            return this.$guard.user;
        },
    },
    methods: {
        ...mapActions(['setTempUser', 'setToken']),

        onOpenDialogProfileUser() {
            this.diProfile = true;
        },
        logout() {
            eventBus.$emit('loading');
            apiService
                .logOut()
                .then(() => {
                    eventBus.$emit('loaded');
                    this.setToken(null);
                    this.setTempUser(null);
                    this.$guard.user = null;
                    this.$emit('eClose');
                    eventBus.$emit('displaySnackbar', {
                        type: 'success',
                        title: 'Đăng xuất thành công',
                        timeout: 3000,
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    },
};
</script>

<style></style>
