<template>
    <div>
        <div
            :class="
                classNames(
                    'absolute   z-30',
                    'translate-x-[-50%] translate-y-[-50%] bg-white shadow-md rounded-lg',
                    `${width} ${height} ${top} ${left}`,
                )
            ">
            <span
                class="mdi mdi-close cursor-pointer"
                :class="classNames('absolute top-1 right-3 text-gray-600 text-[25px]')"
                @click="$emit('eClose')"></span>
            <slot></slot>
        </div>
        <div :class="classNames('bg-black z-20 w-screen h-screen absolute', 'top-0 left-0 opacity-40')"></div>
    </div>
</template>

<script>
//Mixins
import mixinPrettyClassname from '@/components/app/mixins/mixinPrettyClassname';

export default {
    name: 'TDialog',
    mixins: [mixinPrettyClassname],
    data() {
        return {};
    },

    props: {
        top: {
            type: String,
            default: 'top-[50%]',
        },
        left: {
            type: String,
            default: 'left-[50%]',
        },
        width: {
            type: String,
            default: 'w-[80%]',
        },
        height: {
            type: String,
            default: 'h-[80%]',
        },
    },

    watch: {},

    computed: {},
    methods: {},
};
</script>

<style scoped>
.t-select {
    height: 350px;
    overflow: auto;
}
</style>
