<template>
    <div class="bg-gray-50 h-[100vh]">
        <div>
            <!-- desktop -->
            <app-header />
            <app-user />
            <router-view />
            <t-loader />
            <t-toast />
            <t-preview-image />
        </div>
        <!-- <div v-else class="flex justify-center">
            <h3>Phiên bản mobile đang phát triển</h3>
        </div> -->
    </div>
</template>

<script>
import AppHeader from '@/components/app/header/AppHeader';
import AppUser from '@/components/app/header/AppUser.vue';
import TLoader from '@/components/tailwind/TLoader.vue';
import TToast from '@/components/tailwind/TToast.vue';
import TPreviewImage from '@/components/tailwind/TPreviewImage.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        AppHeader,
        TLoader,
        TToast,
        AppUser,
        TPreviewImage,
    },
    name: 'App',
    computed: {
        ...mapGetters(['getTempUser']),
    },
    data() {
        return {};
    },
    methods: {},
    created() {
        if (this.getTempUser) {
            this.$guard.user = { ...this.getTempUser };
        }
    },
    mounted() {},
};
</script>

<style lang="scss">
body {
    font-family: 'Be Vietnam Pro', sans-serif !important;
}

.mapboxgl-ctrl-measure {
    height: 40px !important;
    width: 60px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
a.mapboxgl-ctrl-logo {
    visibility: hidden;
}

.mapboxgl-ctrl-top-right {
    right: 60px !important;
    top: 67% !important;
}

html {
    overflow-y: hidden !important;
}

.mapboxgl-popup-content {
    // width: 210px !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
}

.mapbox-gl-draw_polygon {
    display: none !important;
}
.mapbox-gl-draw_line {
    display: none !important;
}
.mapbox-gl-draw_point {
    display: none !important;
}
.marker-text {
    border-radius: 10px;
    text-align: center;
    background: white;
    width: 160px;
    margin-left: -65px;
    margin-top: -20px;
    color: #ff8000;
    z-index: 100;
}
.mapboxgl-popup {
    max-width: 400px;
}
.mapboxgl-popup-close-button {
    color: red !important;
    font-size: 20px !important;
}
.bft-img-button svg {
    position: relative;
    top: 12px;
    margin-left: 10px !important;
    padding-left: 5px !important;
}
.mini-map {
    bottom: 45px !important;
}
.bft-light .bft-input > input,
.bft-light .bft-input > select {
    font-size: 15px !important;
}
.flatpickr-calendar.animate.open {
    font-size: 11px !important;
}
</style>
