export default {
    cartoToken: null,
    tempUser: null,
    tempMapUser: null,

    dialogPopup: {
        filter: false,
        profile: false,
        vr: false,
        note: false,
    },
};
