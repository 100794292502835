<template>
    <div>
        <div
            :class="
                classNames(
                    'rounded-lg cursor-pointer bg-white shadow-bold w-[42px] h-[42px]',
                    'flex items-center justify-center z-10',
                    'absolute top-3 left-3 shadow-lg',
                )
            "
            @click="isActiveMenu = true">
            <span class="text-[25px] mdi mdi-menu text-primary"></span>
        </div>
        <div
            :class="
                classNames(
                    'border-gray-50 z-30 bg-white',
                    'h-screen fixed top-0 left-0 xs:w-[350px] sm:w-[400px] flex flex-col',
                    !isActiveMenu
                        ? '-translate-x-[400px] transition ease-in-out opacity-50 duration-300'
                        : 'translate-x-0 transition ease-in-out opacity-100 duration-300',
                )
            ">
            <div :class="classNames('absolute top-3 right-3 cursor-pointer')" @click="isActiveMenu = false">
                <span class="text-[30px] mdi mdi-close text-primary"></span>
            </div>
            <div class="cursor-pointer">
                <img class=" w-[200px] my-5 mx-2" :src="$utils.apiAsset('api/public/logo-color.svg')" alt="" />
            </div>
            <ul class="pa-0 mt-[0px]">
                <li
                    v-for="(menu, index) in menuItems"
                    :key="index"
                    @mouseover="handleMouseOver(menu)"
                    @mouseout="handleMouseOut(menu)"
                    @click="pushRoute(menu.route)"
                    :class="checkRouteActive(menu) ? 'menu-active' : ''"
                    class="flex items-center py-2 px-3 cursor-pointer relative hover:ct-hover-menu before:bg-white before:transition-all before:duration-200">
                    <i class="text-xl" :class="checkRouteActive(menu) ? menu.icon_active : menu.icon"></i>
                    <span
                        :class="checkRouteActive(menu) ? 'text-primary px-2' : 'px-2'"
                        class="text-md text-gray-500"
                        >{{ menu.label }}</span
                    >
                </li>
            </ul>
            <div class="overflow-auto mt-[0px]">
                <Contact />
            </div>
            <div></div>
        </div>
    </div>
</template>

<script>
import mixinPrettyClassname from '@/components/app/mixins/mixinPrettyClassname';
import Contact from './Contact.vue';

export default {
    components: { Contact },
    mixins: [mixinPrettyClassname],
    data() {
        return {
            isActiveMenu: false,
            itemMenuHover: null,
            menuItems: [
                // {
                //     id: 1,
                //     icon: 'mdi mdi-view-dashboard-outline text-gray-500',
                //     icon_active: 'mdi mdi-view-dashboard text-primary',
                //     label: 'Dashboard',
                //     route: 'HomePage',
                // },
                {
                    id: 2,
                    icon: 'mdi mdi-card-search-outline text-gray-500',
                    icon_active: 'mdi mdi-card-search text-primary',
                    label: 'Tra cứu',
                    route: 'SearchPage',
                },
                // {
                //     id: 3,
                //     icon: 'mdi mdi-family-tree text-gray-500',
                //     icon_active: 'mdi mdi-family-tree text-primary',
                //     label: 'Cây gia phả',
                //     route: 'FamilyTree',
                // },
                {
                    id: 4,
                    icon: 'mdi mdi-map-marker-radius-outline text-gray-500',
                    icon_active: 'mdi mdi-map-marker-radius text-primary',
                    label: 'Nghĩa trang',
                    route: 'CemeteryPage',
                },
                {
                    id: 5,
                    icon: 'mdi mdi-rhombus-split-outline text-gray-500',
                    icon_active: 'mdi mdi-rhombus-split text-primary',
                    label: 'Phần mộ',
                    route: 'TombPage',
                },
                // {
                //     id: 6,
                //     icon: 'mdi mdi-clipboard-account-outline text-gray-500',
                //     icon_active: 'mdi mdi-clipboard-account text-primary',
                //     label: 'Người mất',
                //     route: 'DeadUserPage',
                // },
                // {
                //     id: 7,
                //     icon: 'mdi mdi-text-box-check-outline text-gray-500',
                //     icon_active: 'mdi mdi-text-box-check text-primary',
                //     label: 'Dịch vụ',
                //     route: 'ServicesPage',
                // },
                // {
                //     id: 8,
                //     icon: 'mdi mdi-cog-outline text-gray-500',
                //     icon_active: 'mdi mdi-cog text-primary',
                //     label: 'Cài đặt',
                //     route: 'SettingPage',
                // },
                // {
                //     id: 9,
                //     icon: 'mdi mdi-login text-gray-500',
                //     icon_active: 'mdi mdi-login text-primary',
                //     label: 'Đăng nhập',
                //     route: 'Login',
                // },
            ],
        };
    },
    computed: {
        activeRoute() {
            return this.$route.name;
        },
    },
    methods: {
        checkRouteActive(menu) {
            if (this.itemMenuHover && this.itemMenuHover === menu.route) {
                return true;
            }
            if (menu.route === this.activeRoute) {
                return true;
            }
            return false;
        },
        pushRoute(route) {
            if (this.$route.name !== route) {
                this.$router.push({
                    name: route,
                });
            } else {
                this.$router.go();
            }
            this.isActiveMenu = false;
        },
        handleMouseOver(menu) {
            // Xử lý khi hover vào một mục
            this.itemMenuHover = menu.route;
        },
        handleMouseOut() {
            // Xử lý khi di chuột ra khỏi mục
            this.itemMenuHover = null;
        },
    },
};
</script>

<style scoped>
.menu-active::before {
    position: absolute;
    left: 0;
    width: 5px;
    content: '';
    height: 70%;
    background: #003343;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>
