var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user)?_c('div',{class:_vm.classNames(
                'rounded-4xl cursor-pointer bg-white shadow-bold w-[42px] h-[42px]',
                'flex items-center justify-center z-10',
                'absolute top-3 right-3 shadow-lg hover:opacity-90 hover:transition-all',
            ),on:{"click":_vm.onOpenDialogProfile}},[_c('div',{staticClass:"relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full"},[_c('span',{staticClass:"font-medium text-gray-600 select-none"},[_vm._v(_vm._s(_vm.$utils.getLastName(_vm.user.name)))])])]):_c('div',{class:_vm.classNames(
                'rounded-4xl cursor-pointer bg-white shadow-bold w-[42px] h-[42px]',
                'flex items-center justify-center z-10',
                'absolute top-3 right-3 shadow-lg hover:opacity-90 hover:transition-all',
            ),on:{"click":_vm.onOpenDialogUser}},[_c('span',{staticClass:"text-[25px] mdi mdi-account-outline text-primary"})]),(_vm.diUser)?_c('t-dialog',{attrs:{"width":"xs:w-[90%] sm:w-[30%]","height":"xs:h-[90%] sm:h-[90%]"},on:{"eClose":function($event){_vm.diUser = false}}},[[_c('ResendOTP',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'resend'),expression:"type === 'resend'"}],on:{"eChangeType":(e) => (_vm.type = e),"eClose":function($event){_vm.diUser = false}}}),_c('Login',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'login'),expression:"type === 'login'"}],on:{"eChangeType":(e) => (_vm.type = e),"eClose":function($event){_vm.diUser = false}}}),_c('register',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'register'),expression:"type === 'register'"}],on:{"eChangeType":(e) => (_vm.type = e),"eClose":function($event){_vm.diUser = false}}})]],2):_vm._e(),(_vm.diProfile)?_c('div',[_c('profile-user',{on:{"eClose":function($event){_vm.diProfile = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }