<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
    <div class="z-50">
        <div class="grid grid-cols-12 gap-2" v-if="sildeActive">
            <div v-for="(img, index) in attachments" :key="index" class="col-span-6">
                <img
                    :src="img.src"
                    width="100%"
                    style="height: 135px; object-fit: cover"
                    @click="currentSlide(index + 1)" />
            </div>
        </div>
        <div id="myModal" class="modal-img">
            <span class="close cursor" @click="closeModal()">&times;</span>
            <div class="modal-content">
                <div class="mySlides">
                    <div class="numbertext">1 / 4</div>
                    <img
                        src="https://fs.vieportal.net/Files/B9E9036FA28046AE9EBB8CF0330665BC/image=jpeg/e17a8896a23f4cfab8bf0d69ad221e19/_MG_9646_size.jpg"
                        style="width: 100%" />
                </div>

                <div class="mySlides">
                    <div class="numbertext">2 / 4</div>
                    <img
                        src="https://fs.vieportal.net/Files/B9E9036FA28046AE9EBB8CF0330665BC/image=jpeg/d10beab784bb48898d59b983b15b16ec/_MG_9663_size.jpg"
                        style="width: 100%" />
                </div>

                <div class="mySlides">
                    <div class="numbertext">3 / 4</div>
                    <img
                        src="https://static.kinhtedothi.vn/w960/images/upload/2022/12/22/truongson5.JPG"
                        style="width: 100%" />
                </div>

                <div class="mySlides">
                    <div class="numbertext">4 / 4</div>
                    <img
                        src="https://static.kinhtedothi.vn/w960/images/upload/2022/12/22/truongson10.JPG"
                        style="width: 100%" />
                </div>

                <a class="prev" @click="plusSlides(-1)">&#10094;</a>
                <a class="next" @click="plusSlides(1)">&#10095;</a>

                <div class="caption-container">
                    <p id="caption"></p>
                </div>

                <div class="img-card">
                    <div class="column">
                        <img
                            class="demo cursor"
                            src="https://fs.vieportal.net/Files/B9E9036FA28046AE9EBB8CF0330665BC/image=jpeg/e17a8896a23f4cfab8bf0d69ad221e19/_MG_9646_size.jpg"
                            style="width: 100%"
                            @click="currentSlide(1)" />
                    </div>
                    <div class="column">
                        <img
                            class="demo cursor"
                            src="https://fs.vieportal.net/Files/B9E9036FA28046AE9EBB8CF0330665BC/image=jpeg/d10beab784bb48898d59b983b15b16ec/_MG_9663_size.jpg"
                            style="width: 100%"
                            @click="currentSlide(2)" />
                    </div>
                    <div class="column">
                        <img
                            class="demo cursor"
                            src="https://static.kinhtedothi.vn/w960/images/upload/2022/12/22/truongson5.JPG"
                            style="width: 100%"
                            @click="currentSlide(3)" />
                    </div>
                    <div class="column">
                        <img
                            class="demo cursor"
                            src="https://static.kinhtedothi.vn/w960/images/upload/2022/12/22/truongson10.JPG"
                            style="width: 100%"
                            @click="currentSlide(4)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '../../eventBus';
export default {
    props: {},
    data() {
        return {
            attachments: [
                {
                    id: 1,
                    src: 'https://fs.vieportal.net/Files/B9E9036FA28046AE9EBB8CF0330665BC/image=jpeg/e17a8896a23f4cfab8bf0d69ad221e19/_MG_9646_size.jpg',
                },
                {
                    id: 2,
                    src: 'https://fs.vieportal.net/Files/B9E9036FA28046AE9EBB8CF0330665BC/image=jpeg/d10beab784bb48898d59b983b15b16ec/_MG_9663_size.jpg',
                },
                {
                    id: 3,
                    src: 'https://static.kinhtedothi.vn/w960/images/upload/2022/12/22/truongson5.JPG',
                },
                {
                    id: 4,
                    src: 'https://static.kinhtedothi.vn/w960/images/upload/2022/12/22/truongson10.JPG',
                },
            ],
            slideIndex: 1,
            sildeActive: false,
        };
    },
    methods: {
        openModal(id = 1) {
            this.sildeActive = true;
            document.getElementById('myModal').style.display = 'block';
            this.slideIndex = id;
            this.showSlides(id);
        },

        closeModal() {
            this.sildeActive = false;
            document.getElementById('myModal').style.display = 'none';
        },

        plusSlides(n) {
            this.showSlides((this.slideIndex += n));
        },

        currentSlide(n) {
            this.showSlides((this.slideIndex = n));
        },

        showSlides(n) {
            let i;
            const slides = document.getElementsByClassName('mySlides');
            const dots = document.getElementsByClassName('demo');
            const captionText = document.getElementById('caption');
            if (n > slides.length) {
                this.slideIndex = 1;
            }
            if (n < 1) {
                this.slideIndex = slides.length;
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = 'none';
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(' active', '');
            }
            slides[this.slideIndex - 1].style.display = 'block';
            dots[this.slideIndex - 1].className += ' active';
            captionText.innerHTML = dots[this.slideIndex - 1].alt;
        },
    },
    created() {
        eventBus.$on('openPreviewImage', this.openModal);
    },
    beforeDestroy() {
        eventBus.$off('openPreviewImage', this.openModal);
    },
    mounted() {},
};
</script>

<style scoped>
* {
    box-sizing: border-box;
}

.row > .column {
    padding: 0 8px;
}

.row:after {
    content: '';
    display: table;
    clear: both;
}

.column {
    float: left;
    width: 25%;
}

/* The Modal (background) */
.modal-img {
    display: none;
    position: fixed;
    z-index: 100 !important;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
}

/* The Close Button */
.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.mySlides {
    display: none;
}

.cursor {
    cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.img-card img {
    margin-bottom: -4px;
    width: 200px;
    height: 200px;
    margin: 0px;
    padding: 0px;
    border: 1px solid gray;
}
.mySlides img {
    margin-bottom: -4px;
    height: 600px;
    margin: 0px;
    padding: 0px;
    border: 1px solid gray;
}

.caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
}

.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

img.hover-shadow {
    transition: 0.3s;
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
