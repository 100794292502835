<template>
    <div>
        <div class="w-full py-8 pl-3 pr-5 text-gray-500">
            <div class="text-sm">
                <span class="text-blue-500">
                    <a href="quanlynghiatrang.info.vn">quanlynghiatrang.info.vn</a>
                </span>
                là một nền tảng tra cứu và quản lý thông tin liệt sỹ, người thân đã mất trong nghĩa trang trên bản đồ
                số, sản phẩm được phát triển bởi <span class="text-primary">TSmap Team</span> với thông điệp tưởng nhớ
                đến những người anh hùng đã hy sinh vì tổ quốc!
            </div>
            <div class="text-sm py-5">
                Sản phẩm liên quan:
                <a class="text-blue-500" href="https://giapha.info.vn/">giapha.info.vn</a>
                <div>
                    Nền tảng tra cứu thông tin cây gia phả dòng họ
                </div>
            </div>
            <!-- <div>
                <span class="text-blue-500 text-sm"> Gia đình là không ai bị bỏ lại phía sau hay lãng quên </span>
            </div> -->
            <div class="h-6"></div>
            <div class="flex">
                <div class="label w-[100px]">
                    <div class="flex items-center">
                        <svg
                            version="1.1"
                            viewBox="0 0 16 16"
                            class="mr-2 svg-icon svg-fill"
                            style="width: 16px; height: 16px">
                            <g clip-path="url(#clip0_1194_189162)">
                                <path
                                    pid="0"
                                    d="M8 8c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.334-1.333c-.733 0-1.333.6-1.333 1.333S7.266 8 7.999 8zm0-6.667c2.8 0 5.333 2.147 5.333 5.467 0 2.12-1.634 4.614-4.894 7.487a.678.678 0 01-.886 0C4.299 11.414 2.666 8.92 2.666 6.8c0-3.32 2.533-5.466 5.333-5.466z"
                                    _fill="#646464"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_1194_189162">
                                    <path pid="1" _fill="#fff" d="M0 0h16v16H0z"></path>
                                </clipPath>
                            </defs></svg
                        ><span class="normal-font !text-[13px] ml-1 whitespace-nowrap">Địa chỉ: </span>
                    </div>
                </div>
                <div class="normal-font !text-[13px] !font-400 flex-1">
                    CS1: 30 Trần Phú Hà Đông Hà Nội. <br />
                    CS2: 327 Thanh Cao Thanh Oai Hà Nội. <br />
                </div>
            </div>
            <div class="h-1"></div>
            <div class="flex">
                <div class="label w-[100px]">
                    <div class="flex items-center">
                        <svg
                            class="mr-2 svg-icon svg-fill w-4 h-4 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 14 20">
                            <path
                                d="M12 0H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM7.5 17.5h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2ZM12 13H2V4h10v9Z" />
                        </svg>
                        <span class="normal-font !text-[13px] ml-1 whitespace-nowrap">SĐT: </span>
                    </div>
                </div>
                <div class="normal-font !text-[13px] !font-400 flex-1">0963752697</div>
            </div>
            <div class="h-1"></div>
            <div class="flex">
                <div class="label w-[100px]">
                    <div class="flex items-center">
                        <svg
                            version="1.1"
                            viewBox="0 0 16 16"
                            class="mr-2 svg-icon svg-fill"
                            style="width: 16px; height: 16px">
                            <g clip-path="url(#clip0_1194_189175)">
                                <path
                                    pid="0"
                                    d="M13.334 2.667H2.667c-.733 0-1.333.6-1.333 1.333v8c0 .733.6 1.333 1.333 1.333h10.667c.733 0 1.333-.6 1.333-1.333V4c0-.733-.6-1.333-1.333-1.333zM13.067 5.5l-4.36 2.726a1.32 1.32 0 01-1.413 0L2.934 5.5a.566.566 0 11.6-.96l4.467 2.793 4.466-2.793a.566.566 0 11.6.96z"
                                    _fill="#646464"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_1194_189175">
                                    <path pid="1" _fill="#fff" d="M0 0h16v16H0z"></path>
                                </clipPath>
                            </defs></svg
                        ><span class="normal-font !text-[13px] ml-1 whitespace-nowrap">Email: </span>
                    </div>
                </div>
                <div class="normal-font !text-[13px] !font-400 flex-1">tsmap23@gmail.com</div>
            </div>
            <div class="h-1"></div>
            <div class="flex">
                <div class="label w-[100px]">
                    <div class="flex items-center">
                        <svg
                            version="1.1"
                            viewBox="0 0 16 16"
                            class="mr-2 svg-icon svg-fill"
                            style="width: 16px; height: 16px">
                            <g clip-path="url(#clip0_1194_189179)">
                                <path
                                    pid="0"
                                    d="M8 8c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.334-1.333c-.733 0-1.333.6-1.333 1.333S7.266 8 7.999 8zm0-6.667c2.8 0 5.333 2.147 5.333 5.467 0 2.12-1.634 4.614-4.894 7.487a.678.678 0 01-.886 0C4.299 11.414 2.666 8.92 2.666 6.8c0-3.32 2.533-5.466 5.333-5.466z"
                                    _fill="#646464"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_1194_189179">
                                    <path pid="1" _fill="#fff" d="M0 0h16v16H0z"></path>
                                </clipPath>
                            </defs></svg
                        ><span class="normal-font !text-[13px] ml-1 whitespace-nowrap">Website: </span>
                    </div>
                </div>
                <div class="normal-font !text-[13px] !font-400 flex-1">quanlynghiatrang.info.vn</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>

<style scoped></style>
