import axios from 'axios';
import store from './store';

class MAPService {
    constructor() {
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
        });
        this.axios.interceptors.request.use((config) => {
            const token = store.getters.getToken;
            if (token) {
                config.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }
            return config;
        });
    }

    // LAYERS
    getLayers() {
        return this.axios.get('api/map/layers');
    }

    getLayerById(id) {
        return this.axios.get(`api/map/layers/${id}`);
    }

    getGeojsons() {
        return this.axios.get('api/geojsons');
    }

    getGeojsonByPath(path) {
        return this.axios.get(`api/geojsons/${path}`);
    }

    // LAYERS
    getModelByPath(path) {
        return this.axios.get(`api/model/${path}`);
    }
}

export default new MAPService();
